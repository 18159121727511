<template>
    <KCourse loader-class="MBcont" course-id="2" title="Анатомия тела" :items="items">
        <div class="MBtextcont">
            <p class='nomargin'><b> На этом занятии нам понадобятся:</b></p>
            <ol>
                <li>Чертеж</li>
                <li>Пластик La Doll</li>
                <li>Проволока или скрепки канцелярские 5 см нерифлёные</li>
                <li>Ватный диск</li>
                <li>Клей Момент ПВА супер столяр (в красной или желтой бутылке)</li>
                <li>Кисть синтетическая, плоская</li>
                <li>Карандаш, линейка</li>
                <li>Круглогубцы, плоскогубцы</li>
                <li>Вода</li>
                <li>Стек для мелкой лепки</li>
            </ol>
        </div>

        <VideoView video-id="9603994d04374dccb17f5f5a56e1b7de"/>

        <div class="MBtextcont">
            <p class='nomargin'><b>Задание:</b></p>
            <p>Подготовить основу для стопы и пальцев, обтянуть пластиком и собрать.</p>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Готовим трафареты", url:"/mycourses/body/1"},
                { title: "Заготовки из пластилина", url:"/mycourses/body/2"},
                { title: "Подготовка к обтяжке", url:"/mycourses/body/3"},
                { title: "Обтяжка пластиком", url:"/mycourses/body/4"},
                { title: "Достаем пластилин", url:"/mycourses/body/5"},
                { title: "Основа кисти", url:"/mycourses/body/6"},
                { title: "Основа стопы", url:"/mycourses/body/7"},
                { title: "Анатомия руки", url:"/mycourses/body/8"},
                { title: "Анатомия кисти", url:"/mycourses/body/9"},
                { title: "Анатомия ноги", url:"/mycourses/body/10"},
                { title: "Анатомия стоп", url:"/mycourses/body/11"},
                { title: "Анатомия тела", url:"/mycourses/body/12"},
            ],
        }
        }
    }
</script>